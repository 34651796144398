.chat__editor__modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(100, 100, 100, 0.75)
}

.chat__editor__modal__window {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 8px;
    outline: none;
    padding: 20px;
}

/* that is not acceptable and MUST be reworked */
/* shift left edge to make media select visible */
.with_chat__editor__modal__window_shift .chat__editor__modal__window {
    left: 5px;
}
.with_chat__editor__modal__window_shift .chat__editor__modal__overlay  {
    left: 650px;
}


@media (max-width: 787px) {
    .chat__editor__modal__window {
        top: 4px; bottom: 4px;
        left: 4px; right: 4px;
    }
}
