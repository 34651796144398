@import 'colors';
@import url(https://fonts.googleapis.com/css?family=Karla:400,700);

.chat__full__iframe {
  font-family: "karla", sans-serif;

  // chat__channel
  flex: 1;
  display: flex;
  flex-direction: row;
  max-width: 760px;
  height: 100%;

  input, button, textarea {
    font-family: "karla", sans-serif !important;
  }

  #chat-wrapper {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    height: 100vh;
    width: 100vw;
  }

  //.sidebar {
  //  flex: 1 0 auto;
  //  max-width: 296px;
  //  width: auto;
  //}


  //.header {
  //  flex: 1 0 auto;
  //  max-width: 296px;
  //  width: auto;
  //  display: none;
  //}

  .content {
    color: var(--text-color);
    background: var(--bg-color);
    line-height: 1.3334;
    font-family: "karla", sans-serif;
    font-size: 16px;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;

    border-left: solid 1px var(--border-color);
    border-right: solid 1px var(--border-color);
    height: 100vh;
    overflow: hidden;

    flex: 1;

    /* for child items */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
 }

  .chat__channels {
    flex: 1;
    max-width: 240px;
    border-right: solid 1px var(--border-color);
    height: 100vh;

    display: flex;
    flex-direction: column;
  }

  .chat__channel {
    flex: 1;
    width: 394px;
    max-width: 560px;
    border-right: solid 1px var(--border-color);
    height: 100vh;

    display: flex;
    flex-direction: column;
  }

  .chat__mobile__header { display: none; }

  @media (max-width: 633px) {
    .chat__channel, .chat__channels { display: none; width: 100%; max-width: 100%; }
    .chat__active__page { display: flex; }
    .chat__mobile__header { display: block; }
  }

  // show 2 pages with no header
  @media (min-width: 634px) {
    .chat__channel, .chat__channels { display: flex; }
  }

  //// show header
  //@media (min-width: 1084px) {
  //  .header {
  //    display: block;
  //  }
  //}

}
