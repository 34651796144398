@import 'colors';
@import url(https://fonts.googleapis.com/css?family=Karla:400,700);

//html, body {
//  width: 100%;
//  height: 100%;
//  margin: 0;
//  padding: 0;
//}


.chat__live__window__in__iframe {
  // chat__channel
  flex: 1;
  display: flex;
  flex-direction: column;

  font-family: "karla", sans-serif;

}

