@import 'colors';
@import url(https://fonts.googleapis.com/css?family=Karla:400,700);

//body.of-layout {
//  color: var(--text-color);
//  background: var(--bg-color);
//  line-height: 1.3334;
//  display: flex;
//  flex-direction: column;
//  font-family: Roboto, sans-serif;
//  font-size: 16px;
//  padding: 0;
//  margin: 0;
//  -webkit-font-smoothing: antialiased;
//}

.chat__ofull__layout {


  padding: 0;
  margin: 0;
  display: flex;

  flex-direction: row;
  flex-flow: row nowrap;
  align-items: flex-start;
  align-content: flex-start;
  box-sizing: border-box;

  height: 100vh;
  width: 100vw;


  // .content {
    color: var(--text-color);
    background: var(--bg-color);
    line-height: 1.3334;
    font-family: "karla", sans-serif;
    font-size: 16px;
    //padding: 0;
    //margin: 0;
    -webkit-font-smoothing: antialiased;

    border-left: solid 1px var(--border-color);
    border-right: solid 1px var(--border-color);
    //height: 100vh;

    // flex: 1;

    /* for child items */
    //display: flex;
    //flex-direction: row;
  // }

  .header {
    flex: 2 3 auto;
    // max-width: 296px;
    // max-width: 394px;
    // min-width: 12em;
    // max-width: 42em;
    max-width: 694px;
    padding-left: 10px;
    box-sizing: border-box;
  }

  .chat__channels {
    flex: 2 1 0;
    min-width: 394px;
    max-width: 394px;
    border-right: solid 1px var(--border-color);
    height: 100vh;

    display: flex;
    flex-direction: column;
    // box-sizing: border-box;
  }

  .chat__channel {
    flex: 2 1 0;
    min-width: 394px;
    max-width: 694px;
    border-right: solid 1px var(--border-color);
    height: 100vh;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  // mobile-first
  .header, .chat__channel, .chat__channels {
    display: none;
    overflow: hidden;
  }
  .chat__active__page { display: flex; }

  @media screen and (max-width: 787px) {
    .chat__channel, .chat__channels { width: 100%; max-width: 100%; }
  }


  @media screen and (min-width: 788px) {
    .chat__channel, .chat__channels { display: flex; width: 100%; max-width: 100%; }
  }

  @media screen and (min-width: 1084px) {
    .header {
      display: block;
      //max-width: 20em;
    }
  }

  @media screen and (min-width: 1380px) {
    .header {
      display: block;
      //max-width: 32em;
    }
  }

// 1125px scroll appears


/*
  @media (max-width: 787px) {
    .chat__channel, .chat__channels { display: none; width: 100%; }
    .chat__active__page { display: flex; }
  }

  // show 2 pages with no header
  @media (max-device-pixel-ratio: 1.9) and (min-width: 788px) {
    .chat__channel, .chat__channels { display: flex; }
  }

  // show header
  @media (min-width: 1084px) {
    .header {
      display: block;
      padding-left: 20px;
      padding-top: 20px;
    }
  }

  // macbook 15'' 1440
  @media (min-width: 1400px) {
    .header {
      display: block;
      padding-left: 20px;
      padding-top: 20px;
      max-width: 500px;
    }
    .chat__channels {
      max-width: 350px;
    }
  }
*/
}

@media screen and (max-width: 787px) {
  body.with_chat__editor__modal__window_shift .header {
    display: flex;
    width: 100%;
    max-width: 100%;
  }
  body.with_chat__editor__modal__window_shift .chat__channels,
  body.with_chat__editor__modal__window_shift .chat__channel {
    display: none;
  }
}