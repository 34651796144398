:root {
  --bg-color: #fff;
  --bg-color-header: #fff;
  --text-color: #242529;
  --overlay-color: rgba(0,0,0,0.5);
  --title-color: #242529;
  --marked-color: #f6f7f8;
  --gray-color: #8a96a3;
  --foreign-message-color: rgba(138,150,163,.12);
  --my-message-color: rgba(0,175,240,.12);
  --btn-active-color: rgb(0, 145, 234);
  --border-color: rgba(138, 150, 163, 0.25);
  --active-channel-color: rgba(0,145,234,.06);
}
