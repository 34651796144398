@import 'colors';
@import url(https://fonts.googleapis.com/css?family=Karla:400,700);

.chat__live__closed__icon {
  font-family: "karla", sans-serif;

  color: gray;
  position: fixed;
  left: 100%;
  top: 100%;
  transform: translateX(-65px) translateY(-65px);
  opacity: 0.7;
}

.chat__live__closed__icon__active {
  animation: blink 2s ease-in infinite;
}

@keyframes blink {
  from, to { opacity: 1 }
  50% { opacity: 0.5 }
}

.chat__live__window {
  position: fixed;
  border: solid 1px #ccc;
  left: 50%;
  width: 394px;
  top: 40px;
  bottom: 40px;
  transform: translateX(-197px);
  z-index: 200;
  background-color: white;

  /// for iframe
  display: table;
  empty-cells: show;
  border-collapse: collapse;
  height: 90%;
}

.chat__live__window__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(100, 100, 100, 0.75);
  z-index: 100;
}

.chat__live__iframe {
  width: 100%; height: 100%; margin: 0; padding: 0; display: block;
}