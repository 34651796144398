@import 'colors';

@import url(https://fonts.googleapis.com/css?family=Karla:400,700);

.chat__user_media_view__iframe {

  font-family: "karla", sans-serif;

  width: 100%;
  height: 100%;

  .filter {
    padding: 6px;
  }

  input, button, textarea {
    font-family: "karla", sans-serif !important;
  }

  .content {
    color: var(--text-color);
    background: var(--bg-color);
    line-height: 1.3334;
    font-family: "karla", sans-serif;
    font-size: 16px;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;

    border-left: solid 1px var(--border-color);
    border-right: solid 1px var(--border-color);
    height: 100vh;

    max-width: 740px;

    /* for child items */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;

    .media_flexblock {
      flex: 1;

      img {
        max-width: 600px;
        max-height: 200px;
      }

    }
  }

}
